// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

// Bootstrap
require("@popperjs/core")
import 'bootstrap'
import * as bootstrap from 'bootstrap';

// Fonts
// const fonts = require.context('../fonts', true)
// const fontPath = (name) => fonts(name, true)

// Images
const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// Import Application Styles
require("../stylesheets/application-edu.sass")

// Import FontAwesome
require("@fortawesome/fontawesome-free/css/all")
require("@fortawesome/fontawesome-free/js/all")

// Listen for events for TurboLinks
document.addEventListener("turbolinks:load", () => {

  // Alerts
  var alertList = document.querySelectorAll('.alert, [data-bs-dismiss="alert"]')
  var alerts =  [].slice.call(alertList).map(function (element) {
    return new bootstrap.Alert(element)
  })

  // Buttons
  var buttonTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="button"]'))
  var buttonList = buttonTriggerList.map(function(buttonTriggerEl) {
      return new Button(buttonTriggerEl)
  })

  // Collapse
  // var collapseElementList = [].slice.call(document.querySelectorAll('.collapse'))
  // var collapseList = collapseElementList.map(function (collapseEl) {
  //   return new bootstrap.Collapse(collapseEl)
  // })

  // Dropdown
  var dropdownElementList = [].slice.call(document.querySelectorAll('.dropdown-toggle, [data-bs-toggle="dropdown"]'))
  var dropdownList = dropdownElementList.map(function (dropdownToggleEl) {
    return new bootstrap.Dropdown(dropdownToggleEl)
  })

  // Offcanvas
  var offcanvasElementList = [].slice.call(document.querySelectorAll('.offcanvas, [data-bs-toggle="offcanvas"]'))
  var offcanvasList = offcanvasElementList.map(function (offcanvasEl) {
    return new bootstrap.Offcanvas(offcanvasEl)
  })

  // Popovers
  var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
  var popoverList = popoverTriggerList.map(function(popoverTriggerEl) {
    return new Popover(popoverTriggerEl)
  })
  
  // Toast
  var toastElList = [].slice.call(document.querySelectorAll('.toast'))
  var toastList = toastElList.map(function (toastEl) {
    return new bootstrap.Toast(toastEl)
  })
    
  // Tooltips
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
  })

})

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
